<template>
  <div class="container bg-first p-1">
    <b-card>
      <div>
        <h3 class="m-0">
          รายการสั่งซื้อสลากฯ
        </h3>
        <!-- <small>คุณเหลือเวลาอีก <span class="text-danger">00 : {{ CountTimeShow ? CountTimeShow: '00 : 00' }} นาที</span> ในการจ่ายเงิน เพื่อเป็นเจ้าของรายการสลาก ฯ ที่ท่านสั่งซื้อ</small> -->
      </div>

      <div class="mt-2">
        <!-- <h5>เฮงมากมาก ค้าสลาก มีทั้งหมด <span class="text-danger">{{ lotto.length }} รายการ</span></h5> -->
      </div>

      <div
        v-for="item in lotto"
        :key="item._id"
        class="border rounded mb-4"
      >
        <!-- {{ item.lottonumber }} <br>
        {{ item.series_no }} <br>
        {{ item.roundth }} <br>
        {{ item.price }} <br> -->
        <div class="row d-flex align-items-center">
          <div class="col-md-3 mb-1 mt-1">
            <div class="lotto-item">
              <!-- <img
              src="/img/lotto-2.png"
              width="100%"
              alt="lottery"
            > -->
              <div class="card6">
                <div class="chip">
                  <span class="bankk">{{ item.lottonumber }}</span>
                </div>
                <div class="number">
                  {{ item.roundth }}
                </div>
                <div class="name">
                  <div class="price-lotto1">
                    <h1>{{ item.price }} บาท</h1>
                  </div>
                </div>
                <div class="from">
                  <span class="txt-a">งวดที่</span> 1
                </div>
                <div class="to">
                  <span class="txt-a">ชุดที่</span> {{ item.series_no }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <tr class="d-flex justify-content-between border-bottom mr-2 ml-1">
              <td style="width: 30%">
                งวดวันที่
              </td>
              <td class="font-weight-bolder font-medium-2 text-primary">
                {{ item.roundth }}
              </td>
              <td>
                1 ใบ
              </td>
            </tr>

            <tr class="d-flex justify-content-between border-bottom mt-2 mr-2 ml-1">
              <td style="width: 30%">
                ราคาต่อหน่วย
              </td>
              <td class="font-weight-bolder font-medium-2 text-primary">
                {{ item.price }}
              </td>
              <td>บาท</td>
            </tr>

            <tr class="d-flex justify-content-between mt-2 mr-2 ml-1">
              <td style="width: 30%" />
              <td class="font-weight-bolder font-medium-2 text-primary" />
              <td>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon"
                  @click="removeLotto(item._id)"
                >
                  <feather-icon icon="TrashIcon" /> ลบสลาก
                </b-button>
              </td>
            </tr>
            <!--
            <tr class="d-flex justify-content-between border-bottom pt-2 pb-2">
              <td style="width: 15%">
                รับโบนัส
              </td>
              <td>0</td>
              <td>บาท</td>
            </tr> -->
          </div>
        </div>
      </div>
      <!-- <div class="mt-4">
        <h4 class="text-divider">
          <span class="text-danger text-center">ต้องการเพิ่มโบนัส</span>
        </h4>
        <div class="d-flex align-items-center justify-content-between">
          <small>เพิ่มโอกาสรับโบนัสเพิ่มอีก <span class="text-danger">10%</span> เมื่อคุณถูกรางวัล เพียงเพิ่มเงินอีก 20 บาทต่อใบ!</small>

          <button class="btn btn-warning">
            รับโบนัส
          </button>
        </div>
      </div> -->

      <div class="mt-2">
        <h4 class="text-divider">
          <span class="text-danger text-center">รวมทั้งหมด {{ Commas(Number(round.price) * lotto.length) }} บาท</span>
        </h4>
        <div class="mt-1">
          <!-- <h3>โอนเงินมาที่</h3> -->
          <br>
          <!-- <h5>ยอดเงินคงเหลือของคุณ: {{ Commas(balance) }} บาท. กรุณาเติมเงินขั้นต่ำ <span class="text-danger">{{ Commas(Number(round.price) * lotto.length) }} บาท</span></h5> -->
          <button
            class="btn btn-gradient-primary"
            @click="buyLotto"
          >
            ซื้อสลากฯ
          </button>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import momenttz from 'moment-timezone'

export default {
  name: 'Order',
  components: {
    BCard,
    BButton,
    // BPagination,
  },
  data() {
    return {
      selected: 'center',
      number: 1,
      selectNum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      lotto: [],
      round: null,
      balance: 0,
      CountTimeShow: '',
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async mounted() {
    await this.getLotto()
    this.getround()
    this.getBalance()
  },
  methods: {
    removeLotto(id) {
      const obj = {
        id,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/removelottoIncart', obj)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.getLotto()
          // console.log(ress.data)
        })
    },
    getround() {
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/lottery/getrounddetail')
        .then(ress => {
          // console.log(ress.data)
          this.round = ress.data
        })
    },
    async getLotto() {
      const obj = {
        UserToken: this.UserData.UserToken,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/getcartbyuser', obj)
        .then(ress => {
          // console.log(ress.data)
          this.lotto = ress.data
        })
    },
    async CalTime() {
      const now = momenttz()
      const duration = await momenttz.duration(now.diff(this.lotto[0].updated_at))
      const Min = await duration.asMinutes()
      // console.log(10 - Min)
      const time = await momenttz().add(-30, 'minutes')
      if (this.lotto[0].updated_at < time) {
        // this.Cancel()
      } else {
        this.startTimer(60 * (30 - Min))
      }
    },
    async startTimer(duration) {
      let timer = duration
      let minutes
      let seconds
      this.intervalID = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)

        minutes = minutes < 10 ? `0${minutes}` : minutes
        seconds = seconds < 10 ? `0${seconds}` : seconds
        // console.log(seconds)
        this.CountTimeShow = `${minutes} : ${seconds}`
        // console.log(this.CountTimeShow)
        // eslint-disable-next-line no-plusplus
        if (--timer < 0) {
          timer = duration
        }
        if (minutes === '00' && seconds === '00') {
          clearInterval(this.intervalID)
          // this.DelWaittingByUID()
        }
      }, 1000)
    },
    getBalance() {
      const Obj = {
        UserToken: this.UserData.UserToken,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/wallet/show', Obj)
        .then(ress => {
          // console.log(ress.data.WalletData.balance)
          this.balance = ress.data.WalletData.balance
          localStorage.setItem('balance', JSON.stringify(ress.data.WalletData.balance))
        })
    },
    buyLotto() {
      this.$swal({
        title: 'แจ้งเตือน ?',
        text: 'ยืนยันที่จะซื้อสลากหรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          this.buyLotto2()
        } else {
          this.show = false
          this.$swal('ยกเลิก', 'ยกเลิกการซื้อสลาก !', 'error')
        }
      })
    },
    buyLotto2() {
      if (this.lotto.length <= 0) {
        this.SwalError('กรุณาเลือก สลาก ที่ต้องการซื้อ')
      } else {
        this.$http
          .post('https://api.sabaideelotto.com/api/seamless/lottery/buylotto', {
            lotto: this.lotto,
            UserToken: this.UserData.UserToken,
          })
          .then(() => {
            this.Success('สั่งซื้อสำเร็จ')
            this.lotto = []
            this.$router.push({ name: 'lotto-list' })
          }).catch(error => {
            this.SwalError(error.response.data.message ? error.response.data.message : 'สั่งซื้อไม่สำเร็จ')
          })
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ซื้อสลากไม่สำเร็จ!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },

  head() {
    return {
      title: 'รายการสั่งซื้อสลากฯ',
    }
  },
}

</script>

<style scoped>
.lotto-item {
    border-radius: 5px;
    /* box-shadow: 0 0 10px #6d6d6da1; */
}
.lotto-item img {
  min-width: 400px;
    max-width: 100%;
    height: auto;
}

                    .card6 {
                        background: url(/img/lotto-2.jpg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin: auto;
                        position: relative;
                        height: 159px;
                        max-width: 317px;
                        min-width: 317px;
                        backdrop-filter: blur(30px);
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                        overflow: hidden;
                    }

                    .logo img {
                        top: 15px;
                        right: 10px;
                        width: 80px;
                        height: auto;
                        opacity: 0.8;
                    }

                    .chip img {
                        top: 60px;
                        left: 20px;
                        width: 50px;
                        height: auto;
                        opacity: 0.8;
                    }

                    .bankk {
                        top: 13px;
                        right: 48px;
                        font-size: 1.2rem;
                        font-weight: 800;
                        letter-spacing: 8px;
                        color: rgb(0, 0, 0);
                    }

                    .txt-a {
                        top: 80px;
                        right: 46px;
                        font-size: 0.7rem;
                        font-weight: 500;
                        padding: 1px 3px;
                        border-radius: 5px;
        color: rgb(0, 0, 0);
            background-color: #01B295;
            margin-bottom: 0;
        /* สีของตัวหนังสือ และ พื้นหลัง*/
        }

        .number {
            right: 52px;
            font-size: 0.8rem;
            font-weight: 600;
            top: 70px;
            color: rgb(0, 107, 89);
        }

        .name {
            /* font-size: 1rem; */
            left: 5px;
            bottom: -7px;
        }

        .from {
            font-size: 0.8rem;
            bottom: 12px;
            color: #000000;
            font-weight: 700;
            right: 120px;
        }

        .to {
            font-size: 0.8rem;
            bottom: 12px;
            color: #000000;
            font-weight: 700;
            right: 50px;
        }
.card-body {
    padding: 0.9rem !important;
}
</style>
